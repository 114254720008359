.buttonDownload{
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
    background: var(--stratos-950, #07074E);
    color: white;
    margin-top: .3em;
    float: right;
    border: #0b7ec4;
    border-radius: 100px;
    width: 10em;
    height: 38px;
    font-family: "Inter" !important;
    font-size: "11px" !important;
}

.buttonDownload:hover{
    opacity: .85;
}

.buttonDownload:active {
    border: #0b7ec4;
}


h1.page-title {
    margin-top: .4em!important;
    margin-bottom: 0!important;
}

.input-search {
    margin-bottom: 1em!important;
}

.text-right {
    text-align: right;
}

.tab-container {
    background: aqua;
}

.card .card-body .display-4 {
    font-size: 1.2em!important;
}

.form-group {
    margin-top: .3em!important;
    margin-bottom: .3em!important;
}

.form-filters {
    margin-bottom: 0!important;
    padding: 0!important;
    background: transparent!important;
    border: none!important;
    gap: .8em;
}

.form-filters .form-group {
    flex-grow: 1;
}

.form-filters .dropdown {
    width: 100%;
    border-radius: .2em;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px!important;
}

.form-filters .dropdown button {
    width: 100%;
    text-align: left;
    max-height: 2.9em;
}

.form-filters .dropdown button div {
    display: inline-block;
}

/* Icon del dropdown */
.form-filters .dropdown button div svg {
    margin-right: .4em;
}

.form-filters .dropdown button div .avatar {
    max-height: 1.8em;
    max-width: 1.8em;
    margin-right: .4em;
}

.form-filters .dropdown button:after {
    position: absolute;
    right: .4em;
    top: 40%;
}

.form-filters .dropdown .btn {
    background: transparent;
    color: #555555!important;
}

/*.btn-datepicker {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px!important;
    overflow: hidden;
}

.react-datepicker-wrapper, .react-datepicker__input-container {
    width: 82% !important;
    margin-left: .2em;
}*/

.list-group .dropdown .btn.dropdown-toggle {
    width: 100%;
    text-align: left;
    margin-top: 2px;
}

.dashboard-item-activity {
    float: left;
}

.dashboard-item-activity .dropdown {
    width: 85%;
    float: left;
    display: inline-block;
    background-color: transparent;
}

.dashboard-item-activity .dropdown button {
    background-color: transparent;
    padding-left: 0;
}

.dashboard-item-activity .bullet-parent {
    height: 2.6em;
    display: inline-block;
    width: 15%;
    float: left;
}

.dashboard-item-activity .form-fieldset {
    margin-left: 1em;
}

.bullet-item-activity {
    width: 40%;
    height: 40%;
    margin: 35% auto;
}

.btn-asignar {
    margin: .8em 0 .8em .8em;
    float: right;
}

.alert {
    margin-top: .3em;
    padding: .2em .8em;
    font-size: .9em;
}

.vertical-line{
    display: inline-block;
    border-left: 2px solid #ccc;
    margin: 10px 6px;
    height: 350px;
}

.title-windows-visited {
    margin-top: 1em;
}

.padding-left-app {
    padding-left: 0.5em;
}

.div-app-windows-clean {
    padding-top: .4em;
    padding-bottom: 0.4em;
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: row;
}

.div-app-windows-clean h6 {
    cursor: pointer;
    width: 92%;
    display: inline-block;
    margin-bottom: 0;
}

.div-app-windows-clean .dropdown {
    cursor: pointer;
    font-size: 1.2em;
}

/*.div-app-windows-clean .name-app {
    width: 10em;
    display: inline-block;
    font-size: .9em;
    white-space: pre-line;
}*/

.div-app-windows-clean .time-app {
    width: 40%;
    height: 1.6em;
    display: inline-block;
    padding: .3em;
    border-radius: .2em;
    font-size: .9em;
}

.div-app-windows-clean .img-icon-app {
    max-width: 4em;
    max-height: 4em;
    margin-right: .4em;
    top: .3em;
}

.div-app-windows-clean .app-icon {
    display: inline-block;
    margin-right: .4em;
    min-width: 1.3em;
    font-size: 1.2em;
}

.div-app-windows-clean .caret-icon {
    margin-right: .5em;
    margin-bottom: .3em;
    margin-left: .5em;
}

.div-app-window-clean-new {
    display: flex; 
    width: 100%;
    align-items: center;
    flex-direction: row;
}

.div-app-window-clean-new .name-app{
    width: 10em !important;
}

.div-windows-parent {
    width: 100%;
    padding-top: .5em;
}

.list-category-parent h6 {
    cursor: pointer;
    width: 92%;
    display: inline-block;
    margin-bottom: 0;
}

.list-category-parent .dropdown {
    cursor: pointer;
    font-size: 1.2em;
}

.list-category-parent .name-app {
    width: 14em;
    display: inline-block;
    /*font-size: .9em;*/
    white-space: pre-line;
}

.list-category-parent .time-app-category {
    width: 40%;
    height: 1.6em;
    display: inline-block;
    /*padding: 0 .3em 0 0.3em;*/
    border-radius: 0 .2em 0.2em 0;
    /*font-size: .9em;*/
}

.list-category-parent .img-icon-app {
    max-width: 4em;
    max-height: 4em;
    margin-right: .4em;
    top: .3em;
    margin-top: -0.4em;
}

.list-category-parent .app-icon {
    display: inline-block;
    margin-right: .4em;
    min-width: 1.3em;
    font-size: 1.2em;
}

.list-category-parent .caret-icon {
    margin-right: .5em;
    margin-bottom: .3em;
    margin-left: .5em;
}

.div-app-windows {
    padding: .4em;
}

.div-app-windows h6 {
    cursor: pointer;
    width: 92%;
    display: inline-block;
    margin-bottom: 0;
}

.div-app-windows .dropdown {
    cursor: pointer;
    font-size: 1.2em;
}

.btn-show {
    padding: .2em 2em!important;
    background-color: white;
}

.btn-show .btn-circle {
    display: inline-block;
    margin-right: .6em;
    color: #23DBB8;
}

.item-window {
    margin-bottom: 0.2em;
    background-color: #FAF3D3;
    white-space: break-spaces;
}
.list-category-parent {
    margin-left: 1em;
    border-bottom: 1px solid var(--sand-200, #DCDCDC);
}

.list-category-parent .btn-pill {
    margin-top: .5em;
    margin-bottom: .2em;
}

.max-heigth-to-category {
    max-height: 28em;
    overflow-y: auto;
}

.list-app-parent {
    margin-left: 1em;
    border-bottom: 1px solid var(--sand-200, #DCDCDC);
}

.list-app-parent .btn-pill {
    margin-top: .5em;
    margin-bottom: .2em;
}

.div-app-windows .name-app {
    width: 44%;
    display: inline-block;
    font-size: .9em;
    white-space: pre-line;
}

.div-app-windows .time-app {
    height: 1.6em;
    display: inline-block;
    border-radius: .2em;
    font-size: .9em;
}

.time-graphical-line-wrapper {
    width: 100%;
    display: inline-block;
    cursor: pointer;
}

.time-graphical-line-wrapper:hover{
    opacity: .85;
}

.table-vcenter .table-align-timeline td{
    vertical-align: top !important;
}

.time-graphical-line-wrapper span {
    /*padding: .1em;*/
    display: inline-block;
    height: 1.6em;
}

.time-graphical-line-wrapper-non-click {
    /*width: 100%;*/
    display: inline-block;
    cursor: default !important;
}

.time-graphical-line-wrapper-non-click span {
    padding: 1px;
    display: inline-block;
    height: 1.6em;
}

.time-extract-first{
    border-radius: 7px 0px 0px 8px;
}

.time-extract-last{
    border-radius: 0px 7px 8px 0px;
}

.time-extract-unique{
    border-radius: 7px 7px 8px 8px;
}

.div-app-windows .img-icon-app {
    max-width: 4em;
    max-height: 4em;
    margin-right: .4em;
    top: .3em;
    margin-top: -0.4em;
}

.div-app-windows .app-icon {
    display: inline-block;
    margin-right: .4em;
    min-width: 1.3em;
    font-size: 1.2em;
}

.div-app-windows .caret-icon {
    margin-right: .5em;
    margin-bottom: .3em;
    margin-left: .5em;
}

.time-app-wrapper {
    width: 55%;
    display: inline-block;
}

.time-app-wrapper span {
    /*padding: .2em;*/
    display: inline-block;
    white-space: nowrap;
}

.time-app-wrapper span span {
    z-index: 99;
    font-size: .9em;
}

.time-app-wrapper .time-app-text {
    position: absolute;
}

.time-app-settings {
    width: 2em;
    float: right;
}

.loader {
    margin: 18% auto;
}

.div-app-windows h6:hover {
    opacity: .85;
}

h6 > .fe {
    margin-right: .4em;
}

.check-desktop-alert {
    min-height: 2.5em;
    font-size: .9em!important;
    margin-top: -1.4em!important;
    margin-bottom: .4em!important;
    padding: 0.4em!important;
}

.check-desktop-alert button {
    padding: .2em!important;
}

.check-desktop-alert i {
    margin-top: -.5em;
}

.check-desktop-alert label {
    margin-left: 3em;
}

.btn-download-xlsx {
    margin-top: .4em;
    float: right;
}

.row-search {
    height: 2em;
    margin-top: .6em;
    margin-bottom: 1.8em;
    padding-bottom: 0;
}

.margin-bottom-24 {
    margin-bottom: .5em!important;
}

.select-category option:nth-child(1),
.select-category option:nth-child(2){
    font-weight: bold;
}

.show-more {
    text-align: center;
    font-weight: bold;
    background-color: #e4e4e4;
    padding-top: 0;
    padding-bottom: 0;
}

.show-more:hover {
    opacity: .8;
    cursor: pointer;
}

.form-filters .form-control {
    min-height: 2.8em;
}

.dropdown-users {
    min-width: 8em;
}

.dropdown-users .btn {
    background-color: white;
    border: solid thin lightgray;
    height: 3.2em!important;
}

.dropdown-users button {
    min-width: 100%;
    padding-right: 1.6em;
}

.dropdown-users .avatar {
    margin-right: .6em;
}

.dropdown-users button::after {
    position: absolute;
    right: .4em;
    top: 50%;
}

.dropdown-item-all {
    cursor: pointer;
    text-align: center!important;
}

.nav-link.active {
    background-color: #23DBB8!important;
    padding: .6em 1.4em!important;
    border-radius: 3em!important;
    border-color: #23DBB8!important;
    color: white!important;
    font-weight: bold!important;
}

.nav-tabs {
    border-bottom: none!important;
}

.chart-title {
    padding-left: .8em;
    margin-bottom: 0;
    margin-top: .4em;
    color: var(--stratos-950, #07074E);
}

.row-pie-charts {
    margin-top: 4em;
}

.tooltip-pie {
    padding: .2em .6em;
    color: #555555;
    background: rgba(255, 255, 255, 0.9);
}

.total-time-pie {
    margin-bottom: -2em;
    margin-top: .4em;
    color: #777777;
    font-weight: bold;
}

.card-dashboard {
    height: 710px;
}

.dropdown-btn {
    width: 94% !important;
    max-height: 2.2em!important;
}

.overflow > .table-responsive {
    overflow-x: hidden !important;
    overflow-y: hidden;
}

@media (max-width: 1280px) {
    .overflow > .table-responsive {
        overflow-x: hidden !important;
        overflow-y:hidden;
    }
}

@media (max-width: 992px) {
    .overflow > .table-responsive {
        overflow-x: hidden !important;
        overflow-y: hidden;
    }
}

@media (max-width: 768px) {
    .overflow > .table-responsive {
        overflow-x: auto !important;
        overflow-y: hidden;
    }
}

@media (max-width: 576px) {
    .overflow > .table-responsive {
        overflow-x: auto !important;
        overflow-y: hidden;
    }
}

/* Lista de aplicaciones relevantes */
.list-app-parent:nth-child(1) .time-app, .time-graphical-app:nth-child(1) { background-color: #68C8BA; }
.list-app-parent:nth-child(2) .time-app, .time-graphical-app:nth-child(2) { background-color: #E3759A; }
.list-app-parent:nth-child(3) .time-app, .time-graphical-app:nth-child(3) { background-color: #9A64E2; }
.list-app-parent:nth-child(4) .time-app, .time-graphical-app:nth-child(4) { background-color: #ED9A55; }
.list-app-parent:nth-child(5) .time-app, .time-graphical-app:nth-child(5) { background-color: #E4555A; }
.list-app-parent:nth-child(6) .time-app, .time-graphical-app:nth-child(6) { background-color: #FFCDCF; }
.list-app-parent:nth-child(7) .time-app, .time-graphical-app:nth-child(7) { background-color: #E9C545; }
.list-app-parent:nth-child(8) .time-app, .time-graphical-app:nth-child(8) { background-color: #92CF52; }
.list-app-parent:nth-child(9) .time-app, .time-graphical-app:nth-child(9) { background-color: #64A8EC; }
.list-app-parent:nth-child(10) .time-app, .time-graphical-app:nth-child(10) { background-color: #6875C7; }
.list-app-parent:nth-child(11) .time-app, .time-graphical-app:nth-child(11) { background-color: #78B737; }


/*Lista de categorias relevantes*/
.list-category-parent:nth-child(1) .time-app-category, .time-graphical-app:nth-child(1) { background-color: #68C8BA; }
.list-category-parent:nth-child(2) .time-app-category, .time-graphical-app:nth-child(2) { background-color: #E3759A; }
.list-category-parent:nth-child(3) .time-app-category, .time-graphical-app:nth-child(3) { background-color: #9A64E2; }
.list-category-parent:nth-child(4) .time-app-category, .time-graphical-app:nth-child(4) { background-color: #ED9A55; }
.list-category-parent:nth-child(5) .time-app-category, .time-graphical-app:nth-child(5) { background-color: #E4555A; }
.list-category-parent:nth-child(6) .time-app-category, .time-graphical-app:nth-child(6) { background-color: #FFCDCF; }
.list-category-parent:nth-child(7) .time-app-category, .time-graphical-app:nth-child(7) { background-color: #E9C545; }
.list-category-parent:nth-child(8) .time-app-category, .time-graphical-app:nth-child(8) { background-color: #92CF52; }
.list-category-parent:nth-child(9) .time-app-category, .time-graphical-app:nth-child(9) { background-color: #64A8EC; }
.list-category-parent:nth-child(10) .time-app-category, .time-graphical-app:nth-child(10) { background-color: #6875C7; }
.list-category-parent:nth-child(11) .time-app-category, .time-graphical-app:nth-child(11) { background-color: #78B737; }

/* Lista de aplicaciones NO relevantes */
.list-app-parent.irrelevant:nth-child(1) .time-app, .time-graphical-app.irrelevant:nth-child(1) { background-color: #989898; }
.list-app-parent.irrelevant:nth-child(2) .time-app, .time-graphical-app.irrelevant:nth-child(2) { background-color: #BDBDBD; }
.list-app-parent.irrelevant:nth-child(3) .time-app, .time-graphical-app.irrelevant:nth-child(3) { background-color: #4A4A4A; }
.list-app-parent.irrelevant:nth-child(4) .time-app, .time-graphical-app.irrelevant:nth-child(4) { background-color: #6A6A6A; }
.list-app-parent.irrelevant:nth-child(5) .time-app, .time-graphical-app.irrelevant:nth-child(5) { background-color: #DCDCDC; }
.list-app-parent.irrelevant:nth-child(6) .time-app, .time-graphical-app.irrelevant:nth-child(6) { background-color: #474A4E; }

.list-category-parent.irrelevant:nth-child(1) .time-app-category, .time-graphical-app.irrelevant:nth-child(1) { background-color: #989898; }
.list-category-parent.irrelevant:nth-child(2) .time-app-category, .time-graphical-app.irrelevant:nth-child(2) { background-color: #BDBDBD; }
.list-category-parent.irrelevant:nth-child(3) .time-app-category, .time-graphical-app.irrelevant:nth-child(3) { background-color: #4A4A4A; }
.list-category-parent.irrelevant:nth-child(4) .time-app-category, .time-graphical-app.irrelevant:nth-child(4) { background-color: #6A6A6A; }
.list-category-parent.irrelevant:nth-child(5) .time-app-category, .time-graphical-app.irrelevant:nth-child(5) { background-color: #DCDCDC; }
.list-category-parent.irrelevant:nth-child(6) .time-app-category, .time-graphical-app.irrelevant:nth-child(6) { background-color: #474A4E; }