tr {
    border: none !important;
}

td {
    border: none !important;
}

.btn-add-user {
    float: right;
}

.btn-save {
    margin-top: .3em;
    margin-left: 1em;
}

.card-add-user {
    padding: 1em;
    margin-bottom: .8em!important;
}

.col-btns-form-add {
    text-align: right;
    align-self: end;
}

.btn-options {
    margin: 0 .5em;
}

.span-num-accounts {
    float: right;
    margin-top: -.8em;
    margin-bottom: .6em;
}

.card-bulk-upload {
    padding: 1em 2em;
    margin-bottom: .8em!important;
}

.col-team-profiles-select {
    padding-bottom: 0!important;
}

.col-team-profiles-select-label {
    margin-top: .5em;
    padding-bottom: 0!important;
}

.usr-teams-profile-item {
    border-bottom: 1px solid lightgray;
}

.usr-teams-profile-item .team {
    background-color: #efefef;
}

.usr-teams-profile-item .profiles {
    font-size: .9em;
    background-color: #f6f6f6;
    white-space: break-spaces;
}

.multi-select.state-invalid div {
    border-color: #EE0202;
}