h1, .h1{
    font-weight: inherit !important;
}

.text-primary {
    font-size: 32px !important;
}

@media (min-width: 768px) {
    .card-body-lg{
        width: 500px !important;
    }
}

@media (min-width: 480px) {
    .card-body-lg{
        width: 380px !important;
    }
}
