.form-label {
    padding-top: 12px !important;
}

.size-form {
    font-size: 18px !important;
}

.size-form-sm {
    font-size: 16px !important;
}

.size-button{
    width: 300px !important;
}

.form-footer{
    align-items: center;
    justify-content: center;
    display: flex;
}