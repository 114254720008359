.row-odd {
    background: #F8F8F8;
}

.row-log.error td {
    color: #EF3E2D;
}

.link-export {
    background-color: #6693CB;
    padding: 10px 22px!important;
    border-radius: 100px;
    color: white;
    font-size: .7rem;
    margin-left: 4px;
}