.prod-text {
    font-size: 14px;
    color: #050536;
    letter-spacing: 0px;
}

.prod-title {
    font-size: 29px;
    color: #495057;
    letter-spacing: 0px;
    opacity: 1;
}

.prod-title-card {
    font-size: 25px;
    color: #000000;
    letter-spacing: 0px;
    opacity: 1;
    font-weight: bold;
}

.div-data-card {
    width: 100%;
    text-align: center;
    padding: 0%;
    margin-top: 0.5em;
}

.name-user {
    height: 85px;
}

@media (max-width: 768px) {
    .div-under-utilization {
        margin-top: -1.5em !important;
    }
    .name-user {
        height: 85px;
    }
}

@media (max-width: 992px) {
    .name-user {
        height: 85px;
    }
}

@media (max-width: 1280px) {
    .name-user {
        height: 85px;
    }
}

.prod-container {
    width: 100.4%;
    position: absolute;
    bottom: 0.1%;
    right: -0.2%;
}

.prod-triangle-green {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    clip-path: polygon(0 0, 0% 100%, 100% 100%);
    background: #BFE3AA;
    bottom: 0;
}

.prod-triangle-red {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    clip-path: polygon(0 0, 0% 100%, 100% 100%);
    background: #FFB299;
    bottom: 0;
}

.prod-percentage {
    position: relative;
    z-index: 2;
    color: white;
}

.prod-square {
    position: relative;
    width: 38px;
    height: 38px;
    border-radius: 7px;
    background: white;
    text-align: center;
    margin-left: 10px;
    display: inline-block;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.prod-square-a {
    position: relative;
    top: 6px;
    color: #495057;
    font-size: 13px;
}

.prod-square-a:hover {
    color: black;
}

.prod-square:hover {
    background: #CED4DA;
}

.prod-square-active {
    width: 38px;
    height: 38px;
    border-radius: 10px;
    background: #CED4DA;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.prod-pagination {
    position: relative;
    display: inline-block;
    float: right;
    padding: 10px 0px 0px 0px;
}

.prod-div {
    cursor: pointer;
}

.prod-height {
    height: 100% !important;
    text-align: center;
    min-height: 390px;
}
.non-back-border {
    background: none !important;
    border: none !important;
    margin-bottom: 0 !important;
}

.normal-font .input-range__label-container {
    font-family: "acumin-pro", sans-serif !important;
}
.normal-font .input-range__track--active {
    background: #00428B !important;
}
.normal-font .input-range__slider {
    background: #00428B !important;
}

.mrg-left {
    margin-left: 0.5em !important;
}

.test-waves {
    border-top-left-radius: 50% 100%;
    border-top-right-radius: 50% 100%;
    position: absolute;
    bottom: 0;
    width: 90%;
    background-color: #0f0f10;
    height: 200px;
}

section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 400px;
    padding-top: 100px;
    background: #3c31dd;
}

.curve {
    position: absolute;
    height: 250px;
    width: 100%;
    bottom: 0;
    text-align: center;
}

.curve::before {
    content: '';
    display: block;
    position: absolute;
    border-radius: 100% 50%;
    width: 55%;
    height: 100%;
    transform: translate(85%, 60%);
    background-color: hsl(216, 21%, 16%);
}

.curve::after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 100% 50%;
    width: 55%;
    height: 100%;
    background-color: #3c31dd;
    transform: translate(-4%, 40%);
    z-index: -1;
}

.box {
    --size: 30px;
    --R: calc(var(--size)*1.28);
    -webkit-mask:
            radial-gradient(var(--R) at 50% calc(1.8*var(--size)),#000 99%,#0000 101%)
            calc(50% - 2*var(--size)) 0/calc(4*var(--size)) 100%,
            radial-gradient(var(--R) at 50% calc(-0.8*var(--size)),#0000 99%,#000 101%)
            50% var(--size)/calc(4*var(--size)) 100% repeat-x;
    background: linear-gradient(#E67553,#E67553, #E67553);
    height: 30%;
    margin-left: -1.50rem !important;
    margin-bottom: -23px !important;
    width: 122%;
    margin-right: -1.5rem !important;
}