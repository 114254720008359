@charset "UTF-8";

$primary-color: #07074E;
$secondary-color: #486AAD;
$red-color: #E4555A;
$gray1-color: #989898;
$gray2-color: #F5F5F5;
$black200-color: #B2CAFF;
$sand600-color: #4A4A4A;
$sand500-color: #6a6a6a;
$sand300-color: #BDBDBD;
$sand200-color: #DCDCDC;
$black100-color: #D4E3FF;
$black950-color: #050536;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap');

/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * ofelia-display:
 *   - http://typekit.com/eulas/00000000000000007735f6e9
 *   - http://typekit.com/eulas/00000000000000007735f6eb
 *   - http://typekit.com/eulas/00000000000000007735f6ed
 *   - http://typekit.com/eulas/00000000000000007735f6e3
 *   - http://typekit.com/eulas/00000000000000007735f6ea
 *
 * © 2009-2023 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2023-06-07 21:44:10 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=gzd4wlx&ht=tk&f=49185.49186.49191.49193.49196&a=62073688&app=typekit&e=css");

@font-face {
  font-family:"ofelia-display";
  src:url("https://use.typekit.net/af/59f42f/00000000000000007735f6e9/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/59f42f/00000000000000007735f6e9/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/59f42f/00000000000000007735f6e9/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}

@font-face {
  font-family:"ofelia-display";
  src:url("https://use.typekit.net/af/3f43a4/00000000000000007735f6eb/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/3f43a4/00000000000000007735f6eb/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/3f43a4/00000000000000007735f6eb/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:700;font-stretch:normal;
}

@font-face {
  font-family:"ofelia-display";
  src:url("https://use.typekit.net/af/914f59/00000000000000007735f6ed/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/914f59/00000000000000007735f6ed/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/914f59/00000000000000007735f6ed/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:400;font-stretch:normal;
}

@font-face {
  font-family:"ofelia-display";
  src:url("https://use.typekit.net/af/642591/00000000000000007735f6e3/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff2"),url("https://use.typekit.net/af/642591/00000000000000007735f6e3/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff"),url("https://use.typekit.net/af/642591/00000000000000007735f6e3/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:300;font-stretch:normal;
}

@font-face {
  font-family:"ofelia-display";
  src:url("https://use.typekit.net/af/e8e25e/00000000000000007735f6ea/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/e8e25e/00000000000000007735f6ea/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/e8e25e/00000000000000007735f6ea/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

.tk-ofelia-display { font-family: "ofelia-display",sans-serif; }

h1, h2, h3, h4, h5, h6 {
  font-family: "ofelia-display",sans-serif;
  font-style: normal;
}

body, p, button, input, textarea {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.color-primary {
  color: $primary-color;
}

.color-secondary {
  color: $secondary-color;
}

.color-red {
  color: $red-color;
}

.color-gray1 {
  color: $gray1-color;
}

.color-sand600 {
  color: $sand600-color!important;
}

.color-sand500 {
  color: $sand500-color!important;
}

.color-black950 {
  color: $black950-color!important;
}

.home-project-detail-label {
  color: $gray1-color;
}

.background-secondary {
  background-color: $secondary-color!important;
}

.background-black200 {
  background-color: $black200-color!important;
}

.background-gray {
  background-color: $gray2-color!important;
}

.background-sand300 {
  background-color: $sand300-color!important;
}

.background-sand200 {
  background-color: $sand200-color!important;
}

.background-black100 {
  background-color: $black100-color!important;
}

.btn-avatar-profile:hover {
  cursor: pointer;
  opacity: .9;
}

.MuiDrawer-paper {
  //max-width: 60px!important; // CAUSA QUE NO SE VEA CORRECTAMENTE ASIGNAR USUARIOS
}

.list-item-left-menu {
  font-size: .8rem!important;
}

#menu-drawer .MuiListItemIcon-root {
  color: $primary-color;
}

.main-footer {
  background: $black950-color;
  border-top: 1px solid rgba(0, 40, 100, 0.12);
  font-size: 0.75rem;
  padding: 1.25rem 0;
  color: $gray2-color;
  text-align: center;
  min-height: 100px;
  height: auto;
}

.MuiTablePagination-toolbar {
  align-items: baseline !important;
}

.sub-title {
  font-size: 22px;
  font-weight: 500;
  color: #07074E;
  font-family: "ofelia-display", sans-serif;
  line-height: normal;
  font-style: normal;
}

.sub-title.section {
  font-size: 18px !important;
}

.react-datepicker-wrapper {
  width: 100%;
}