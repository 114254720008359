
.task-row:hover {
    cursor: pointer;
    background-color: #eeeeee;
}

.task-row {
    border-bottom: solid thin #f3f3f3;
}

.row-project-name {
    font-weight: bold;
    color: #555555;
}

.row-phase-name {
    font-weight: bold;
    color: rgb(39, 83, 94);
}

.row-task-name {
    font-weight: bold;
    color: rgb(85, 61, 116);
}

.item-status-2 {
    font-weight: bold!important;
    color: #486AAD!important;
}

.item-status-3 {
    font-weight: bold!important;
    color: #6a6a6a!important;
}

.item-status-4 {
    font-weight: bold!important;
    color: #78B737!important;
}

.item-list-status-2 {
    font-weight: bold!important;
    color: #486AAD!important;
    padding-top: 0!important;
    padding-bottom: 0!important;
}

.item-list-status-3 {
    font-weight: bold!important;
    color: #6a6a6a!important;
}

.item-list-status-4 {
    font-weight: bold!important;
    color: #78B737!important;
}

/*tbody tr:nth-child(odd) {
    background-color: #f6f6f6;
}*/

.dnd-item {
    color: #555555;
    width: 100%;
}

.dnd-item.dragging {
    background-color: #D3DBFF;
    border: solid 1px #1D3863;
    width: 100%;
}

.dnd-droppable-items {
    background-color: inherit;
    min-height: 100px;
    height: 86%;
}

.dnd-droppable-items.isDraggingOver {
    background-color: rgba(255, 205, 207, 0.51);
}

.btn-assign-usr {
    clear: both!important;
    display: block!important;
}

.home-project-detail-label {
    font-size: .8rem;
}

.title-element-card {
    font-weight: bold;
    font-size: .8rem;
    color: #626262;
}

.table-card-element-phase-task tr td {
    padding-top: 0!important;
    padding-bottom: 0!important;
    vertical-align: super;
}