
.detail-table tbody tr td {
    font-size: 0.75rem;
}

.project-detail-task-row:hover {
    cursor: pointer;
}

.box-show-more:hover {
    cursor: pointer;
}

.btn-remove {
    color: #7E363A!important;
    border-color: #7E363A!important;
}

.btn-show-all {
    color: #6875C7!important;
    border-color: #6875C7!important;
}

.btn-add-user {
    font-size: 0.75rem!important;
    float: left;
    color: #6875C7!important;
    border-color: #6875C7!important;
    padding-top: 0!important;
    padding-bottom: 0!important;
}

.detail-table th:nth-child(1) {
    width: 25%;
}

.detail-table th:nth-child(2) {
    width: 9%;
}

.detail-table th:nth-child(3) {
    width: 9%;
}

.detail-table th:nth-child(4) {
    width: 9%;
}

.detail-table th:nth-child(5) {
    width: 9%;
}

.detail-table th:nth-child(6) {
    width: 9%;
}

.detail-table th:nth-child(7) {
    width: 9%;
}

.detail-table th:nth-child(8) {
    width: 9%;
}

.detail-table td:nth-child(1) {
    width: 21%;
    padding-left: 0!important;
    padding-right: 0!important;
}

.detail-table td:nth-child(2) {
    width: 9%;
    padding-left: 0!important;
    padding-right: 0!important;
}

.detail-table td:nth-child(3) {
    width: 9%;
    padding-left: 0!important;
    padding-right: 0!important;
}

.detail-table td:nth-child(4) {
    width: 8%;
    padding-left: 0!important;
    padding-right: 0!important;
}

.detail-table td:nth-child(5) {
    width: 9%;
    padding-left: 0!important;
    padding-right: 0!important;
}

.detail-table td:nth-child(6) {
    width: 8%;
    padding-left: 0!important;
    padding-right: 0!important;
}

.detail-table td:nth-child(7) {
    width: 8%;
    padding-left: 0!important;
    padding-right: 0!important;
}

.detail-table td:nth-child(8) {
    width: 8%;
    padding-left: 0!important;
    padding-right: 0!important;
}