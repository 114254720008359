.primary-button {
    border-radius: 100px;
    background-color: #07074E;
    /*border-color: #07074E;*/
}

.primary-button:hover {
    background-color: #19149E;
}

.primary-button:focus {
    background-color: #19149E;
    border-color: #6693CB;
}

.secondary-small-buttton {
    border-radius: 100px;
    border: 1px solid var(--steel-blue-500, #6693CB);
    background: var(--steel-blue-500, #6693CB);
}

.title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    flex: 1 0 0;
    padding: 70;
}

.title-small {
    color: var(--sand-600, #4A4A4A);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

h2 {
    color: var(--sand-600, #4A4A4A);
    font-family: Ofelia Display;
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}