.card-item {
    justify-content: space-around !important;
}

.card {
    /*height: 600px;*/
}
.card-status {
    /*height: 24px!important;*/
}

.row-cards.row-deck > :first-child{

}

.head-pricing-first {
    border-top: 16px solid #23DBB8 !important;
    border-radius: 8px 8px 0px 0px;
    opacity: 1;
}

.head-pricing-second {
    border-top: 16px solid #00428B !important;
    border-radius: 8px 8px 0px 0px;
    opacity: 1;
}

.status-secondary{
    height: 64px !important;
}

.height-icon {
    height: 80px;
}

.price-buttons{
    margin-top: 24px;
    margin-bottom: 12px;
}

#attribute-list{
    height: 300px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    white-space: break-spaces;
    /*vertical-align: middle;
    width: 380px;*/
}

.container-pricing-card {
    justify-content: center;
}