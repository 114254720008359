.alert-left {
    float: left;
    color: #2f66b3;
    margin-top: 1em;
    margin-left: 1em;
}

.alert-right {
    float: right;
    color: #2f66b3;
    margin-top: 1em;
    margin-right: 1em;
}

.x-buy {
    float: right;
    margin-top: -1em;
    margin-right: -1em;
    cursor: pointer;
}

.trial-row {
    padding-top: 1em;
}

.trial-hide {
    visibility: hidden;
    height: 0px;
}