
.btn-element-collapsible {
    padding: 0;
    height: 2.2em;
    width: 2.2em;
}

.title-elements {
    margin-top: -.7em!important;
    margin-bottom: -1em!important;
    color: #666666;
}

.div-phases-container {
    padding-left: 1em;
}

.h4-title-info {
    margin-bottom: -.4em!important;
}

.h4-title-elements {
    margin-bottom: -.4em!important;
    margin-top: 1em!important;
}

.estimate-numbers {
    font-size: 4em;
}

.task-name {
    font-size: .9em;
    margin-left: -1em;
}

.table-activities th {
    text-transform: capitalize;
    font-weight: bold;
    color: #888888;
}

.btn-collapse-first-card {
    margin-top: -1em;
    margin-right: -1em;
    float: right;
}

.tr-users-table > td {
    padding: 0!important;
}

.table thead {
    height: .8em!important;
}

.table thead th {
    padding-top: 0!important;
    padding-bottom: 0!important;
}

.tr-users-table td {
    padding-top: 0!important;
    padding-bottom: 0!important;
}

.table td {
    padding-top: .2em!important;
    padding-bottom: .2em!important;
}

.custom-border {
    border-bottom: 1px solid #dee2e6;
}

.task-item-row-disabled {
    color: #bbbbbd;
}

.estimation-rows {
    background-color: #f2f3f3;
    margin-left: -1.88rem;
    margin-right: -1.88rem;
    padding: 0.2em;
    min-height: 2.6em;
}

.margin-bottom-min {
    margin-bottom: 0.2em;
}

.estimation-card-body {
    padding-top: 0 !important;
    padding-bottom: 0;
}
.estimation-headers-first {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
    margin-left: 0.5em !important;
}

.estimation-headers {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

.estimation-rows-phase {
    background-color: #f2f3f3;
    margin-left: -2.8rem;
    margin-right: -1.88rem;
}

.activity-table-header {
    background-color: #f2f3f3;
    margin-left: -4.12rem;
    margin-right: -2.25rem;
}

.overflow-row {
    overflow-x: auto;
}

.phase-name-identation {
    padding-left: 2.5rem;
}

.activity-name-identation {
    padding-left: 1rem;
}

.padding-left-min {
    padding-left: 0.1em;
}

.padding-right-min {
    padding-right: 0.1em;
}

.activity-row {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.activity-row-line {
    min-height: 0.2em;
    background-color: #8f979e;
    margin-left: -1.88rem;
    margin-right: -1.88rem;
}

.input-duration .form-control {
    max-width: 60px;
    height: 2em;
}

.input-duration .btn {
    height: 2.3em;
    padding: 0.375rem 0.75rem;
}

.row-element {
    margin-bottom: -.4em;
}

.card-header {
    max-height: 1em!important;
    min-height: 3.4em!important;
    padding: 0;
}

.phases-title {
    border-top: solid lightgrey 1px;
    margin-left: -3em;
    margin-right: -2em;
    padding-top: .6em;
}

.div-phases-container {
    margin-top: 1em;
}

.min-prom-max {
    height: 26px;
}

.row-title-activities {

}

.div-activities {
    border: solid thin #f2f2f2;
    margin-right: -2rem;
}

.row-activity {
    border-top: solid thin #efefef;
}

.row-users-title {
    border-top: solid thin #f1f1f1;
    border-bottom: solid thin #f1f1f1;
    background-color: #f1f1f1;
}

.row-users-header {
    border-top: solid thin #f1f1f1;
}