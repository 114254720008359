.table-days input {
    padding: 0 !important;
    height: 2em !important;
    width: 5em !important;
    font-size: 0.8em !important;
    text-align: center;
}

.row-day {
    cursor: pointer;
    background-color: #f8f8f8;
    margin-bottom: .1em;
    padding: .2em .5em;
}

.row-day .label {
    max-width: 85%;
    display: inline-block;
}

.row-day .icon-add {
    float: right;
    display: inline-block;
}

.card-form-day {
    padding: .8em;
}

.red {
    color: #e23e3d;
}

.day-hours-sort-item {
    margin-bottom: .5em;
}

.day-hours-sort-item li {
    list-style: none;
}

.btn-apply-all {
    float: right;
    color: white !important;
}

.btn.btn-success {
    color: white !important;
}

.react-datepicker-wrapper a.btn {
    min-height: 2.6em !important;
}

.circle-button {
    background: #23DBB8;
    color: white;
    border-radius: 50%;
}

.circle-button:hover {
    color: white;
    opacity: 0.6;
}

.background-show-sched {
    background: #FBFBFB;
}

.on-pointer {
    cursor: pointer;
}

.on-pointer:hover {
    color: red;
}

.color-cyan {
    color: #23DBB8;
}

.border-button {
    border: 1px solid hsl(0, 0%, 80%);
}

.inline-row{
    display: flow-root !important;
}

.btn-circle-schedule {
    display: inline-block;
    margin-right: .6em;
    background-color: #00428B;
    border: #00428B;
    font-size: 0.8125rem;
    font-weight: 600;
    font-family: inherit;
    padding: 0.375rem 0.75rem;
    border-radius: 3px;
    color: white;
    cursor: pointer;
}

.btn-circle-schedule:hover {
    color: white;
    opacity: 0.6;
}