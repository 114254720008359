.status {
    border-radius: 9px;
    opacity: 1;
    height: 2rem;
    width: 7rem;
    display: flex;
    vertical-align: middle;
    justify-content:center;
    align-items: center;
}

.padding-line {
    padding-bottom: 1em;
}

.col-center {
    display: flex;
    flex-direction: column;
    padding-bottom: 1em;
}

.card-task {
    height: 70%;
}

.card-task68 {
    height: 68%;
}

.card-bar-line {
    height: 280px;
}

.card-body-home {
    padding-top: 1em;
    padding-bottom: 0.7em;
    padding-left: 1.5rem;
}

.card-body-trend {
    padding-left: 0.7em;
    padding-top: 1em;
}

.control-order {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
    font-size: 0.8125rem;
    font-family: inherit;
}

.heigh-card-data-9em {
    height: 9em;
}

.heigh-card-data-95em {
    height: 9.5em;
}

.heigh-card-data-11em {
    height: 11em;
}

.heigh-card-data-12em {
    height: 12em;
}

.align-row {
    flex-direction: row;
    justify-content: center;
}

.form-search {
    width: 100%;
}

.padding-form {
    padding-bottom: 0.3em;
}

.without-padding-form {
    padding-bottom: 0em;
}

.dropdown-home {
    height: 2.9em !important;
    max-height: 2.9em !important;
    font-weight: 100;
}

.card-velocity {
    display: flex;
    flex-direction: row;
}

.width-cards {
    width: 110%;
}

.width-donuths-cards {
    width: 120%;
}

.col-cards {
    margin-left: -1.7em;
}

.align-tasks {
    display: flex;
}

.title-head-mini-card {
    justify-content: center;
}

.home-empty-card {
    display: flex;
    max-width: 540px;
    padding: 19px 0px 31px 0px;
    flex-direction: column;

    gap: 20px;

    border-radius: 12px;
    background: var(--sand-50, #F5F5F5);
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.home-empty-card-1 {
    width: 540px;
    height: 60px;
    text-align: right;
}

.home-empty-card-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.home-empty-box-1 {
    display: flex;
    padding: 20px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    text-align: center;

    border-radius: 6px;
    background: var(--sand-10, #FFF);
    /* elevation/1 */
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.home-empty-box-options {
    display: flex;
    width: 95%;
    align-items: flex-start;
    gap: 40px;
}

.show-more-div {
    display: flex;
    height: 24px;
    padding: 5px 12px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: 5px;
    background: var(--sand-100, #EFEFEF);
}

.show-more-div:hover {
    cursor: pointer;
}

.MuiAvatar-root.MuiAvatar-circular.MuiAvatar-colorDefault.MuiAvatarGroup-avatar {
    height: 30px;
    width: 30px;
}

.div-select-type-info:hover {
    cursor: pointer;
}

.div-select-type-info {
    background: "#F5F5F5"!important;
    padding: "6px" !important;
    border: "solid thin #64A8EC"!important;
}

.div-select-type-info.selected {
    background: "#050536";
    color: white;
}

.hr-white-line-logo {
    display: block;
    height: 2px;
    border: 0;
    border-top: 2px solid white;
    margin: -3em 0;
    padding: 0;
    z-index: 0;
}

.home-empty-card-2-title {
    text-align: left!important;
    margin-top: 4rem;
    padding-left: 1rem;
}

.home-empty-card-2-title h5 {
    font-weight: normal;
}